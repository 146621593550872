import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import * as Heading from "@components/heading"
import BreadCrumbs from "../components/breadcrumbs";

export default function Template({ data }) {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    return (
        <Layout>
            <Heading.H1 text={frontmatter.title} />
            <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
                <BreadCrumbs
                    lists={[
                        {
                            string: "会社情報",
                            path: "/company",
                        },
                        {
                            string: "お知らせ",
                            path: "/news",
                        },
                        {
                            string: `${frontmatter.title}`,
                        }
                    ]}
                />
                <div className="ml-4 mr-4">
                    <Heading.H2 text={frontmatter.date} />
                    <div
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </div>
        </Layout>
    )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      id
      frontmatter {
        date(formatString: "YYYY年MM月DD日")
        title
      }
    }
  }
`